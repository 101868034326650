import request from '@/utils/request';

export const postHelpFile = (formData: FormData, file: string) => {
    switch(file) {
        case 'page1':
            return request({
                url: `/Help/PostNewPage1`,
                method: 'post',
                data: formData
            });
        case 'journal':
            return request({
                url: `/Help/PostNewJournal`,
                method: 'post',
                data: formData
            });
        default:
            return new Promise<never>(() => { /* do nothing. */ });
    }
}
    
